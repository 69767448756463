import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'

import loadable from '@loadable/component'
import { useVaultContext } from '../hooks/useVault'
import Box from '@material-ui/core/Box'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { AdminProductProvider } from './hooks/useAdminProducts'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'

const CreateTokenView = loadable(() =>
  import(/* webpackChunkName: "CreateTokenView", webpackPrefetch: 1 */ './views/CreateTokenView'),
)

const ManageProductsView = loadable(() =>
  import(/* webpackChunkName: "ManageProductsView", webpackPrefetch: 1 */ './views/ManageProductsView'),
)

const MainView = loadable(() => import(/* webpackChunkName: "MainView", webpackPrefetch: 1 */ './views/MainView'))
const Transactions = loadable(() => import(/* webpackChunkName: "EventLogs", webpackPrefetch: 1 */ './views/EventLogs'))

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    display: 'flex',
  },
  sectionTitle: {
    color: theme.palette.textPrimary,
    fontWeight: 600,
    textTransform: 'none',
    minWidth: '100px',
  },
}))
export const DashboardAppRoutes = () => {
  const { isDesktop } = useVaultContext()
  const [value, setValue] = React.useState(0)
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    const positions = ['/dashboard/main', '/dashboard/products', '/dashboard/transactions']
    const position = positions.indexOf(location.pathname) || 0
    setValue(position)
  }, [location.pathname])

  return (
    <AdminProductProvider>
      <Route path="/dashboard">
        <Box display="flex" flexDirection="column" flexGrow="2">
          <Header />
          {value !== -1 && (
            <Box mt={3} display="flex" pl={isDesktop ? '8%' : 2} pr={isDesktop ? '8%' : 2}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                variant="fullWidth"
                centered
                style={{ width: isDesktop ? 'auto' : '100%' }}
              >
                <Tab
                  label="Main View"
                  className={classes.sectionTitle}
                  onClick={() => history.push('/dashboard/main')}
                />
                <Tab
                  label="Products"
                  className={classes.sectionTitle}
                  onClick={() => history.push('/dashboard/products')}
                />
                <Tab
                  label="Transactions"
                  className={classes.sectionTitle}
                  onClick={() => history.push('/dashboard/transactions')}
                />
              </Tabs>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            flexGrow="2"
            pl={isDesktop ? '8%' : 2}
            pr={isDesktop ? '8%' : 2}
            pt={3}
            overflowX="hidden"
          >
            <Route
              exact
              path="/dashboard/products"
              render={(props) => <ManageProductsView {...props} preload={[]} />}
            />
            <Route exact path="/dashboard/main" render={(props) => <MainView {...props} preload={[]} />} />
            <Route exact path="/dashboard/transactions" render={(props) => <Transactions {...props} preload={[]} />} />

            <Route
              exact
              path="/dashboard/create-token/:productId"
              render={(props) => <CreateTokenView {...props} preload={[]} />}
            />
          </Box>
          <Footer />
        </Box>
      </Route>
    </AdminProductProvider>
  )
}
