/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { lohkoBackendApi } from '../utils/constants'
import { useVaultContext } from './useVault'
import { useZippieIdContext } from './useZippieId'

export const useUserSettings = () => {
  const { lohkoEnv } = useVaultContext()
  const { accessToken } = useZippieIdContext()

  const [userSettings, setUserSettings] = useState({
    defaultCurrency: 'LOHKO-EUR',
    termsOfService: {
      version: 'v1',
      isAccepted: false,
    },
  })
  const [isAdmin, setIsAdmin] = useState(false)
  const [userId, setUserId] = useState('')

  const [has2AFActive, setHas2FAActive] = useState(false)
  const [hasValidKyc, setHasValidKyc] = useState(false)

  const [isUserBlackListed, setIsUserBlacklisted] = useState(false)
  const [pubkey, setPubKey] = useState()

  const onStart = async () => {
    const savedUserSettings = localStorage.getItem('userSettings')
    if (savedUserSettings) {
      setUserSettings(JSON.parse(savedUserSettings))
    }
  }
  const addUserSettings = (uSettings) => {
    const newUserSettings = { ...userSettings, ...uSettings }

    localStorage.setItem('userSettings', JSON.stringify(newUserSettings))
    setUserSettings(newUserSettings)
  }
  const updateUserSettings = async (payload, accToken) => {
    const token = accessToken || accToken
    const newUserSettings = { ...userSettings, ...payload }
    localStorage.setItem('userSettings', JSON.stringify(newUserSettings))

    const response = await axios.post(
      `${lohkoBackendApi[lohkoEnv]}/users/update_user_settings`,
      {
        settings: newUserSettings,
      },
      { headers: { 'x-access-token': token } },
    )
    setUserSettings(newUserSettings)
  }

  useEffect(() => {
    onStart()
  }, [])

  return {
    userSettings,
    updateUserSettings,
    isAdmin,
    has2AFActive,
    hasValidKyc,
    isUserBlackListed,
    setIsAdmin,
    setHas2FAActive,
    setHasValidKyc,
    setIsUserBlacklisted,
    setUserSettings,
    pubkey,
    setPubKey,
    addUserSettings,
    setUserId,
    userId,
  }
}

const [UserSettingsProvider, useUserSettingsContext] = constate(useUserSettings)

export { UserSettingsProvider, useUserSettingsContext }
