import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

let theme = createMuiTheme({
  palette: {
    primary: { main: '#023459' },
    secondary: { main: '#BBA464' },
    error: { main: '#D9000C' },
    textPrimary: '#032239',
    textSecondary: '#515D6B',
    text: {
      primary: '#032239',
      secondary: '#515D6B',
    },
    background: {
      default: 'white',
    },
    divider: '#E5E8EC',
  },
  typography: {
    userSelect: 'none',
    fontFamily: [
      'Source Sans Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        height: '40px',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '600',
        letterSpacing: '0',
        flexShrink: '0',
        borderRadius: '0',
      },
      disabled: {
        color: '#637381 !important',
        backgroundColor: '#E5E8EC !important',
      },
      text: {
        userSelect: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0',
      },
    },
    MuiInput: {
      root: {
        borderRadius: '0',
      },
      disabled: {
        color: '#637381 !important',
        borderColor: '#B5BEC8',
        background: '#F5F6F7',
      },
    },
  },
  props: {
    // Name of the component ⚛️
    MuiTextField: {
      // The default props to change
      fullWidth: true,
      variant: 'outlined',
      size: 'small',
    },
    MuiButton: {
      size: 'medium',
      fullWidth: true,
      disableElevation: true,
    },
  },
})

theme = responsiveFontSizes(theme)

export { theme }
