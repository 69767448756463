import React, { useState, useEffect } from 'react'

import ArrowDownIcon from '../assets/icons/arrow-dropdown-down.svg'
import ArrowUpIcon from '../assets/icons/arrow-dropdown-up.svg'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { getInitials } from '../utils/nameInitials'
import { useUserSettingsContext } from '../hooks/useUserSettings'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { useZippieIdContext } from '../hooks/useZippieId'
import { useVaultContext } from '../hooks/useVault'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useNotificationsContext } from '../hooks/useNotifications'

const useStyles = makeStyles((theme) => ({
  menu: {
    top: '90px !important',
  },
}))

export const NameComponent = () => {
  const { userInformation, logout } = useZippieIdContext()
  const { setIsNotificationHistoryOpen, publishUserMessage } = useNotificationsContext()

  const { isDesktop } = useVaultContext()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const nameInitials = userInformation.fullname ? getInitials(userInformation.fullname) : ''

  const history = useHistory()
  const { isAdmin } = useUserSettingsContext()

  return (
    <Box display="flex" style={{ cursor: 'pointer' }} onClick={handleClick} className="header-name">
      <Box
        width={isDesktop ? '52px' : '48px'}
        height={isDesktop ? '52px' : '48px'}
        borderRadius="50%"
        bgcolor="#F5F5F3"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Typography color="primary" style={{ fontSize: '18px', fontWeight: '700' }}>
          {nameInitials}
        </Typography>
      </Box>
      {isDesktop && (
        <>
          <Box display="flex" flexDirection="column" marginLeft="16px">
            <Typography style={{ fontWeight: '600', textTransform: 'capitalize', color: 'white' }}>
              {userInformation.fullname}
            </Typography>

            <Typography variant="body2" style={{ marginTop: '4px', color: 'white' }}>
              Gold member
            </Typography>
          </Box>
          {anchorEl ? (
            <img src={ArrowDownIcon} style={{ marginLeft: '16px' }} />
          ) : (
            <img src={ArrowUpIcon} style={{ marginLeft: '16px' }} />
          )}
        </>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.menu,
        }}
      >
        <MenuItem
          onClick={() => {
            logout()
            handleClose()
          }}
        >
          <Box display="flex" alignItems="center" style={{ width: '140px' }}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 9.75H14.125"
                stroke="#032239"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 12.875L14.125 9.75L11 6.625"
                stroke="#032239"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.13 13.5797C3.99004 15.3465 5.41976 16.7724 7.18888 17.6277C8.95799 18.483 10.9635 18.7179 12.8824 18.2946C14.8013 17.8712 16.5218 16.8144 17.7669 15.2941C19.0119 13.7739 19.709 11.8788 19.7459 9.91412C19.7827 7.94944 19.1572 6.02955 17.97 4.46369C16.7829 2.89782 15.1032 1.77714 13.2015 1.28216C11.2999 0.787174 9.28696 0.946704 7.48701 1.73505C5.68706 2.5234 4.20487 3.89467 3.27917 5.62798"
                stroke="#032239"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Typography style={{ marginLeft: '8px' }}>Log out</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push('/home/settings')
            handleClose()
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsNotificationHistoryOpen(true)
            handleClose()
          }}
        >
          Notifications
        </MenuItem>
        {isAdmin && <MenuItem onClick={() => history.push('/home/admin')}>Admin</MenuItem>}
        {isAdmin && (
          <MenuItem
            onClick={() => {
              history.push('/dashboard/products')
              handleClose()
            }}
          >
            Dashboard
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
