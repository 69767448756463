export const SEND = 'send'
export const RECEIVE = 'receive'
export const CASHIN = 'cashin'
export const CASHBACK = 'cashback'
export const BUY = 'buy'
export const DEPOSIT = 'deposit'
export const TRANSFER = 'transfer'

export const PENDING = 'pending'
export const CANCELLED = 'cancelled'
export const EXPIRED = 'expired'
export const COMPLETED = 'completed'
export const REFUND = 'refund'

const transactionStatusMessage = {
  pending: 'Pending',
  cancelled: 'Cancelled',
  completed: 'Complete',
  pending_conditional: 'Pending - waiting for return period to end',
  refund: 'Refund',
}
const transactionsTypes = {
  receive: 'Deposit',
  deposit: 'Deposit',
  transfer: 'Transfer',
  buy: 'Buy',
}
export const getTransactionType = (type) => {
  switch (type) {
    case 'Sent':
    case 'send':
    case 'SendPending':
    case 'Cancelled':
      return SEND
    case 'Expired':
      return SEND
    case 'ReceivePending':
    case 'Received':
      return RECEIVE

    case 'Cashback':
      return CASHBACK
    case 'From JamboPay':
    case 'cashin':
      return CASHIN
    case 'buy-asset':
      return BUY
    case 'deposit':
      return DEPOSIT
    case 'transfer':
      return TRANSFER
    default:
      return ''
  }
}

const titles = {
  receive: 'Your USD balance',
  deposit: 'Your USD balance',
  buy: 'Assets',
  transfer: 'Transfer asset',
}

export const getTransactionStatus = (type) => {
  switch (type) {
    case 'Sent':
    case 'Received':
      return COMPLETED
    case 'SendPending':
    case 'ReceivePending':
      return PENDING
    case 'Cancelled':
      return CANCELLED
    case 'Expired':
      return EXPIRED
    case 'Refund':
      return REFUND
    default:
      return ''
  }
}
export function parseLocaleNumber(stringNumber) {
  if (typeof stringNumber === 'number') {
    return stringNumber
  }
  var thousandSeparator = (1111).toLocaleString().replace(/1/g, '')
  var decimalSeparator = (1.1).toLocaleString().replace(/1/g, '')

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.'),
  )
}

export const getFormattedTransaction = (transaction) => {
  let type

  if (transaction.type === 'Received' && transaction.metadata && transaction.metadata.type === 'send') {
    type = 'Received'
  } else if (transaction.metadata) {
    if (transaction.metadata && transaction.metadata.type) {
      type = transaction.metadata.type
    } else if (transaction.metadata.transactionInfo && transaction.metadata.transactionInfo.type) {
      type = transaction.metadata.transactionInfo.type
    } else {
      type = transaction.type
    }
  } else {
    type = transaction.type
  }

  const transactionType = getTransactionType(type)
  const transactionStatus = getTransactionStatus(transaction.type)
  const date = new Date(transaction.timestamp * 1000)
  const dateString = date.toLocaleDateString()
  const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
  var options = { year: 'numeric', month: 'short', day: 'numeric' }
  const dateStringV2 = date.toLocaleDateString('en-FI', options)
  const transactionInfo =
    transaction.metadata && transaction.metadata.itemDetails
      ? transaction.metadata.itemDetails
      : transaction.metadata || {}
  const formattedStatus = type === 'Expired' ? 'Expired' : transactionStatusMessage[transactionStatus]
  const formattedType = transactionsTypes[transactionType]
  const today = new Date().toLocaleDateString('en-FI', options)
  const title = titles[transactionType]
  const paymentMethod = transaction.metadata && transaction.metadata.paymentMethod
  const amount = transactionType === 'transfer' ? transactionInfo.price : transaction.amountFormatted

  return {
    amount,
    dateString,
    time,
    formattedStatus,
    dateStringV2: dateStringV2,
    transactionStatus,
    transactionInfo,
    formattedType,
    title,
    paymentMethod,
  }
}
