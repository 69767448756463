import { request, gql } from "graphql-request";
import axios from "axios";

const ethers = require('ethers')
const genericErc20Abi = require('./erc20Abi.json')

export const getSigner = () => {


  const privateObj = JSON.parse(localStorage.getItem('privateKey'))

  const INFURA_API_KEY = 'bd8863c27cdf463f93eaaa498c24d6dc'
  const provider = new ethers.providers.InfuraProvider('homestead', INFURA_API_KEY)

  const signer = new ethers.Wallet(privateObj.privkey, provider);

  return signer
}

const getZiptBalance = async () => {
  const ZIPT_TOKEN_ADDRESS = '0xEDD7c94FD7B4971b916d15067Bc454b9E1bAD980'

    const signer = getSigner()

    const userStore = new ethers.Contract(ZIPT_TOKEN_ADDRESS, genericErc20Abi, signer);
    const balance = await userStore.balanceOf(signer.address)
    const formattedBalance = ethers.utils.formatEther(balance)
    return formattedBalance
}


const getZiptPriceUSD = async () => {
    const ziptId = "0x0f61b24272af65eacf6adfe507028957698e032f";
    const query = gql`
    {
      token(id: "${ziptId}") {
       name
       derivedETH
       feesUSD
       volumeUSD
      }
    }
  `;

  const d = await request(
    "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal",
    query
  );
  const eth = await axios.get(
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum"
  );

  const ethPrice =eth.data[0].current_price
  return Number(d?.token.derivedETH) * ethPrice
}
export { getZiptBalance, getZiptPriceUSD }
