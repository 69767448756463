import createContainer from 'constate'
import { useEffect, useState } from 'react'


export const useLanguage = () => {
  const [language, setLanguage] = useState( 'en')
  const [languages] = useState({
    en: 'English',
    es: 'Español',
    fr: 'Français',
    de: 'Deutsch',
    pt: 'Português',
    ru: 'Русский язык',
    fi: 'Suomi',
    ko: '한국어',
    // 'zh-CN': '简体中文',
    // th: 'ไทย',
  })
  const [languageStrings, setLanguageStrings] = useState({})

  const loadLanguage = async lang => {
    const { default: catalog } = await import(`../locales/${lang}/messages.js`)
    setLanguage(lang)
    setLanguageStrings(state => ({ ...state, [lang]: catalog }))
    
  }

  useEffect(() => {
    const initLanguage = async () => {
      const currentLanguage = null;
      const lang = currentLanguage !== null && currentLanguage !== 'null' ? currentLanguage : 'en'
      await loadLanguage(lang)
    }
    initLanguage()
  }, [])

  return { language, languages, languageStrings, loadLanguage }
}

export const [LanguageProvider, useLanguageContext] = createContainer(useLanguage)
