/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useVaultContext } from '../../hooks/useVault'
import { lohkoBackendApi } from '../../utils/constants'
import { useZippieIdContext } from '../../hooks/useZippieId'

export const useAdminProducts = () => {
  const { lohkoEnv, vaultIsReady } = useVaultContext()
  const { accessToken } = useZippieIdContext()
  const [allUserBalancesSummary, setAllUserBalancesSummary] = useState([])
  const [allAssetsSummary, setAllAssetsSummary] = useState([])
  const [allUserBalances, setAllUserBalances] = useState([])
  const [topProducts, setTopProducts] = useState({})
  const [eventLogs, setEventsLogs] = useState([])
  const [usersSummary, serUsersSummary] = useState([])

  const [ordersSummaryDate, setOrderSummaryDate] = useState('DAY')

  const [isLoading, setIsLoading] = useState(true)

  const [ordersSummary, setOrdersSummary] = useState([])

  const getAllUserBalances = async () => {
    const url = `${lohkoBackendApi[lohkoEnv]}/dashboard/get_user_balances`

    const response = await axios.post(url, {}, { headers: { 'x-access-token': accessToken } })
    return response.data
  }
  const getAllUserBalancesSummary = async () => {
    const url = `${lohkoBackendApi[lohkoEnv]}/dashboard/get_user_balances_summary`

    const response = await axios.post(url, {}, { headers: { 'x-access-token': accessToken } })
    return response.data
  }
  const getAllAssets = async () => {
    const url = `${lohkoBackendApi[lohkoEnv]}/dashboard/get_assets`

    const response = await axios.post(url, {}, { headers: { 'x-access-token': accessToken } })
    return response.data
  }
  const getAllAssetsSummary = async () => {
    const url = `${lohkoBackendApi[lohkoEnv]}/dashboard/get_assets_summary`

    const response = await axios.post(url, {}, { headers: { 'x-access-token': accessToken } })
    return response.data
  }

  const getEventLog = async (fromDate, toDate) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/dashboard/get_event_log`

    const response = await axios.post(
      url,
      {
        fromDate,
        toDate,
      },
      { headers: { 'x-access-token': accessToken } },
    )
    return response.data
  }
  const getAllOrdersSummary = async () => {
    const url = `${lohkoBackendApi[lohkoEnv]}/dashboard/get_orders_summary`

    const response = await axios.post(
      url,
      {
        fromUnit: ordersSummaryDate,
      },
      { headers: { 'x-access-token': accessToken } },
    )
    return response.data
  }
  const getTopProducts = async (fromUnit) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/dashboard/get_top_products`

    const response = await axios.post(
      url,
      {
        fromUnit: fromUnit,
      },
      { headers: { 'x-access-token': accessToken } },
    )
    return response.data
  }
  const getUserSummary = async (fromUnit) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/dashboard/get_users_summary`

    const response = await axios.post(
      url,
      {
        fromUnit: fromUnit ,
      },
      { headers: { 'x-access-token': accessToken } },
    )
    return response.data
  }
  const onDateChange = async () => {
    const orderSummary = await getAllOrdersSummary()
    setOrdersSummary(orderSummary)
  }
  useEffect(() => {
    if (accessToken) {
      onDateChange()
    }
  }, [ordersSummaryDate])
  const onStart = async () => {
    const [allUserBalances, allUserBalancesSummary, allAssets, allAssetsSummary, orderSummary, topProducts, eventLogs, userSummary] =
      await Promise.all([
        getAllUserBalances(),
        getAllUserBalancesSummary(),
        getAllAssets(),
        getAllAssetsSummary(),
        getAllOrdersSummary(),
        getTopProducts(),
        getEventLog(),
        getUserSummary()
      ])
    // getEventLog()
    setAllUserBalancesSummary(allUserBalancesSummary)
    setAllAssetsSummary(allAssetsSummary)
    setOrdersSummary(orderSummary)
    setAllUserBalances(allUserBalances)
    setTopProducts(topProducts)
    setEventsLogs(eventLogs)
    serUsersSummary(userSummary)
    setIsLoading(false)
  }

  useEffect(() => {
    if (accessToken) {
      onStart()
    }
  }, [accessToken])
  const getBullionStarProducts = async () => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/get-buillonstar-products`

    const response = await axios.post(url)
    return response.data
  }

  const publishToken = async (id, publish) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/products/publish`

    const response = await axios.post(
      url,
      {
        id,
        publish,
      },
      { headers: { 'x-access-token': accessToken } },
    )
  }

  const updateToken = async (payload) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/products/update`

    const response = await axios.post(url, payload, { headers: { 'x-access-token': accessToken } })
  }

  const createNewToken = async (payload) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/products/create`

    const response = await axios.post(url, payload, { headers: { 'x-access-token': accessToken } })
  }

  return {
    createNewToken,
    getBullionStarProducts,
    updateToken,
    publishToken,
    allUserBalancesSummary,
    allAssetsSummary,
    ordersSummary,
    isLoading,
    allUserBalances,
    ordersSummaryDate,
    setOrderSummaryDate,
    topProducts,
    eventLogs,
    getEventLog,
    setEventsLogs,
    getTopProducts,
    setTopProducts,
    serUsersSummary,
    usersSummary,
    getUserSummary
  }
}

const [AdminProductProvider, useAdminProductsContext] = constate(useAdminProducts)

export { AdminProductProvider, useAdminProductsContext }
