import React, { useState, useEffect } from 'react'

import { useWalletContext } from '../hooks/useWallet'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDownIcon from '../assets/icons/arrow-dropdown-down.svg'
import { useVaultContext } from '../hooks/useVault'
import ArrowUpIcon from '../assets/icons/arrow-dropdown-up.svg'
import { useUserSettingsContext } from '../hooks/useUserSettings'
import { tokenCurrencies } from '../utils/tokensSymbols'
const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    display: 'flex',
  },
  sectionTitle: {
    color: theme.palette.textPrimary,
    fontWeight: 600,
    textTransform: 'none',
    minWidth: '100px',
  },
  menu: {
    top: '90px !important',
  },
  list: {
    paddingBottom: 0,
  },
}))

export const Balances = () => {
  const [selectedToken, setSelectedToken] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const { isDesktop } = useVaultContext()
  const { userSettings, updateUserSettings } = useUserSettingsContext()
  const { tokens } = useWalletContext()

  const history = useHistory()
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (Object.keys(tokens).length) {
      const selectedToken = Object.values(tokens).find(
        (token) => token.tokenInfo.symbol === userSettings.defaultCurrency,
      )
      setSelectedToken(selectedToken || Object.values(tokens)[0])
    }
  }, [tokens, userSettings])

  const onTokenClick = (token) => {
    setSelectedToken(token)
    updateUserSettings({ defaultCurrency: token.tokenInfo.symbol })
    handleClose()
  }
  return selectedToken ? (
    <>
      <Box display="flex" style={{ cursor: 'pointer' }} onClick={handleClick} className="header-balances">
        <Typography
          color="textPrimary"
          variant={isDesktop ? 'h4' : 'h5'}
          style={{
            fontWeight: '600',
            color: 'white',
          }}
        >
          {tokenCurrencies[selectedToken.tokenInfo.symbol].currencySymbol}{' '}
          {new Intl.NumberFormat().format(selectedToken.quantity)}{' '}
        </Typography>
        {anchorEl ? (
          <img src={ArrowDownIcon} style={{ marginLeft: '16px' }} />
        ) : (
          <img src={ArrowUpIcon} style={{ marginLeft: '16px' }} />
        )}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.menu,
          list: classes.list,
        }}
      >
        {Object.values(tokens).map((token) => {
          return (
            <MenuItem onClick={() => onTokenClick(token)}>
              <Box style={{ minWidth: '120px', maxWidth: '280px', width: '200px' }} display="flex">
                <Box mb={1} display="flex" flexDirection="column">
                  <img
                    src={tokenCurrencies[token.tokenInfo.symbol].logo}
                    style={{ marginRight: '12px', width: '20px', height: '20px' }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {token.tokenInfo.currency || token.tokenInfo.symbol} balance
                  </Typography>
                </Box>

                <Typography color="primary" style={{ fontWeight: '600', marginLeft: 'auto' }}>
                  {tokenCurrencies[token.tokenInfo.symbol].currencySymbol}{' '}
                  {new Intl.NumberFormat().format(token.quantity)}
                </Typography>
              </Box>
            </MenuItem>
          )
        })}
        <MenuItem
          onClick={() => {
            history.push('/withdraw')
            handleClose()
          }}
          style={{
            backgroundColor: '#F5F5F3',
            minHeight: '40px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" width={1}>
            <Typography color="primary" style={{ fontWeight: '600' }}>
              Withdraw
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push('/deposit')
            handleClose()
          }}
          style={{
            backgroundColor: '#023459',
            minHeight: '40px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" width={1}>
            <Typography style={{ fontWeight: '600', color: 'white' }}>Deposit</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  ) : null
}
