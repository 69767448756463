import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import LohkoLogo from '../assets/logos/lohko-logo-small.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const LinkComponent = ({ title, path, href, blank }) => {
  return (
    <Typography color="textPrimary" style={{ color: 'white' }} variant="body2">
      {path ? (
        <Link to={path} style={{ color: 'inherit', textDecoration: 'none', marginLeft: '36px' }}>
          {title}
        </Link>
      ) : (
        <a
          href={href}
          style={{ color: 'inherit', textDecoration: 'none', marginLeft: '36px' }}
          target={blank ? '_blank' : ''}
        >
          {title}
        </a>
      )}
    </Typography>
  )
}

export const Footer = () => {
  const matches = useMediaQuery('(min-width:800px)')

  return (
    <Box
      display="flex"
      paddingLeft="8%"
      paddingRight="8%"
      bgcolor="#023459"
      alignItems="center"
      mt="auto"
      style={{ minHeight: matches ? '70px' : '64px' }}
    >
      <img src={LohkoLogo} style={{ objectFit: 'contain', width: '55px' }} />
      {matches && (
        <>
          <Box ml="auto" />
          <LinkComponent title="Support" href="mailto:support@lohkowallet.com" />
          <LinkComponent title="Privacy Policy " href="https://lohkowallet.com/privacy-policy/" blank />
          <LinkComponent title="Terms of Service" href="https://lohkowallet.com/terms-and-conditions/" blank />
          <LinkComponent title="Legal" path="/terms-of-service" />
        </>
      )}
    </Box>
  )
}
