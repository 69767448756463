import React, { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

import { useLocation, useHistory, Link } from 'react-router-dom'
import LohkoLogo from '../assets/logos/lohko-logo.svg'
import LohkoLogoSmall from '../assets/logos/lohko-logo-small.svg'

import { useZippieIdContext } from '../hooks/useZippieId'
import { useVaultContext } from '../hooks/useVault'

import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { Balances } from './BalancesComponents'
import { NameComponent } from './NameComponent'

import { useDashboardContext } from '../hooks/useDashboard'

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    display: 'flex',
  },
  sectionTitle: {
    color: theme.palette.textPrimary,
    fontWeight: 600,
    textTransform: 'none',
    minWidth: '100px',
  },
  toolbar: {
    paddingLeft: '8%',
    paddingRight: ' 8%',
    minHeight: '100px',
  },
  menu: {
    top: '90px !important',
  },
}))

const NavHeader = () => {
  const classes = useStyles()
  const { isDesktop } = useVaultContext()
  const history = useHistory()
  const { isLoggedIn } = useZippieIdContext()

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar} style={{ minHeight: isDesktop ? '100px' : '64px' }}>
        {isDesktop ? (
          <img
            src={LohkoLogo}
            style={{ marginRight: 'auto', width: '136px', marginLeft: '-20px', cursor: 'pointer' }}
            alt=""
            onClick={() => history.push('/home/portfolio')}
          />
        ) : (
          <img
            src={LohkoLogoSmall}
            style={{ marginRight: 'auto', width: '51px' }}
            alt=""
            onClick={() => history.push('/home/portfolio')}
          />
        )}
        {isLoggedIn && (
          <>
            <Balances />
            <Box ml={5} />
            <NameComponent />
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export const Header = () => {
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const { isZippieIdReady, isLoggedIn } = useZippieIdContext()
  const { signin, signup } = useDashboardContext()

  const { vaultIsReady, params, isDesktop } = useVaultContext()
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const onStart = async () => {
    if (!vaultIsReady || !isZippieIdReady || isLoggedIn) {
      return
    }

    if ((params && params.isSignUp) || (params && params.isSignIn)) {
      history.push('/welcome')
    }
  }
  useEffect(() => {
    onStart()
  }, [vaultIsReady, params, isZippieIdReady, isLoggedIn])

  useEffect(() => {
    const positions = ['/home/portfolio', '/home/history', '/home/products', '/home/cover']
    const position = positions.indexOf(location.pathname) || 0
    setValue(position)
  }, [location.pathname])

  return (
    <>
      <NavHeader />
      {value !== -1 && (
        <Box mt={3} display="flex" pl={isDesktop ? '8%' : 2} pr={isDesktop ? '8%' : 2}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
            centered
            style={{ width: isDesktop ? 'auto' : '100%' }}
          >
            <Tab
              label="My portfolio"
              className={classes.sectionTitle}
              onClick={() => history.push('/home/portfolio')}
            />
            <Tab label="Transactions" className={classes.sectionTitle} onClick={() => history.push('/home/history')} />
            <Tab
              label="Products"
              className={classes.sectionTitle}
              onClick={() => history.push('/home/products/gold')}
            />
            <Tab
              label="News"
              className={classes.sectionTitle}
              style={{ marginLeft: 'auto' }}
              onClick={() => history.push('/home/cover')}
            />
          </Tabs>
        </Box>
      )}
    </>
  )
}
