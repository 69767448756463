export const MY_CREDENTIALS_SERVICE_APP = {
  local: '/permastore2/038d6e4409248992db03aed2c100b520f882d853b36560025aa5c50d2a709cc752',
  development: '/ens/zippie/mycredentials-dev.zippie',
  testing: '/ens/zippie/mycredentials-testing.zippie',
  release: '/ens/zippie/mycredentials.zippie',
}

export const ZIPPIE_ID_APP = {
  development: '/ens/zippie/id-dev.zippie',
  testing: '/ens/zippie/id-testing.zippie',
  release: '/ens/zippie/id.zippie',
}

export const ZIPPIE_URL = {
  development: 'https://dev.zippie.com',
  testing: 'https://testing.zippie.com',
  release: 'https://zippie.com',
}

export const LOHKO_URL = {
  development: 'https://app.dev.lohkowallet.com',
  testing: 'https://app.test.lohkowallet.com',
  release: 'https://app.lohkowallet.com',
}

export const lohkoBackendApi = {
  local: 'https://de-sparklers.dev.zippie.com/lohko-sparkler-dev.zippie/api',
  development: 'https://de-sparklers.dev.zippie.com/lohko-sparkler-dev.zippie/api',
  testing: 'https://de-sparklers.testing.zippie.com/lohko-sparkler-testing.zippie/api',
  release: 'https://de-sparklers.zippie.com/lohko-sparkler.zippie/api',
}

export const merchantIds = {
  development: 'lohko-dev-v3.merchant',
  testing: 'lohko-testing-v3.merchant',
  release: 'lohko-v3.merchant',
}

export const sumsubApi = {
  development: 'https://test-api.sumsub.com',
  testing: 'https://api.sumsub.com',
  release: 'https://api.sumsub.com',
}
