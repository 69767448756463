/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { ZIPPIE_URL } from '../utils/constants.js'
import { useHistory } from 'react-router-dom'

import { useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Vault from '@zippie/vault-api'
export const useVault = ({ contentParams }) => {
  const [environment, setEnvironment] = useState()
  const [lohkoEnv, setLohkoEnv] = useState()

  const [params, setParams] = useState(contentParams)
  const isDesktop = useMediaQuery('(min-width:935px)')
  const history = useHistory()

  const [vault, setVault] = useState()
  const [vaultIsLoadingOrLoaded, setVaultIsLoadingOrLoaded] = useState(false)

  const [vaultIsReady, setVaultIsReady] = useState(false)
  const clearParam = (param) => {
    let newParams = { ...params }
    if (newParams[param]) {
      delete newParams[param]
      window.parent.postMessage({ wm_remove_url_param: 'paymentHash' }, '*')

      setParams(newParams)
    }
  }
  const closeKlaatuPopUp = () => {
    const style = `z-index: 0;
    `
    document.getElementById('klaatuembed').style = style
  }
  const openKlaatuPopUp = () => {
    const style = `
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    border: 0;
    z-index: 99999999;
    display: flex;
    background-color: transparent;
    `
    document.getElementById('klaatuembed').style = style
  }
  const handleLoadKlaatu = async () => {
    setVaultIsLoadingOrLoaded(true)
    if (document.getElementById('klaatuembed')) {
      console.error('Loading klaatu again')
      return
    }

    const vaultInstance = new Vault({ klaatu: true, klaatu_embed: { root: document.body, url: ZIPPIE_URL[getEnv()] } })
    await vaultInstance.setup()

    setVault(vaultInstance)
    setVaultIsReady(true)
    console.log('*** VAULT IS READY ***')
  }
  const autoLogout = () => {
    localStorage.clear()

    window.location.href = 'https://lohkowallet.com/'
  }
  const logout = () => {
    console.log('user activation: ', navigator.userActivation ? navigator.userActivation.isActive : 'n/a')

    vault.messageNoAsync({ wm_save_data_popup: {} })
    vault.message({ wm_save_data_popup_wait: {} }).then(async () => {
      await vault.message({ wm_logout: {} })

      localStorage.clear()

      window.location.href = 'https://lohkowallet.com/'
    })
  }

  const getEnv = () => {
    const origin = window.location.origin
    if (origin.includes('dev') || origin.includes('localhost') || origin.includes('ngrok.io')) {
      return 'development'
    }
    if (origin.includes('.test.')) {
      return 'testing'
    }

    return 'release'
  }

  const getLohkoEnv = () => {
    const origin = window.location.origin
    if (origin.includes('localhost')) {
      return 'local'
    }
    if (origin.includes('dev') || origin.includes('ngrok.io')) {
      return 'development'
    }
    if (origin.includes('.test.')) {
      return 'testing'
    }

    return 'release'
  }

  useEffect(() => {
    const env = getEnv()
    const lohkoEnv = getLohkoEnv()
    setEnvironment(env)
    setLohkoEnv(lohkoEnv)

    if (vaultIsLoadingOrLoaded) {
      console.log('*** PREVENTED SECOND LOAD OF VAULT ***')
      return
    }
    handleLoadKlaatu()
  }, [])
  useEffect(() => {
    if (contentParams && contentParams.paymentHash) {
      history.push('/redeem-asset')
    }
  }, [contentParams])
  return {
    environment,
    params,
    vault,
    vaultIsReady,
    isDesktop,
    clearParam,
    openKlaatuPopUp,
    closeKlaatuPopUp,
    logout,
    autoLogout,
    lohkoEnv,
  }
}

const [VaultProvider, useVaultContext] = constate(useVault)

export { VaultProvider, useVaultContext }
