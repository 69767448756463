import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { Button, Typography } from '@material-ui/core'
import { useNotificationsContext } from '../hooks/useNotifications'
import { useHistory, useLocation } from 'react-router-dom'
import { useVaultContext } from '../hooks/useVault'
import { useProductsContext } from '../hooks/useProducts'
import { jsonParser } from '../utils/jsonParser'
import CloseIcon from '../assets/icons/close-icon.svg'
import CloseIconDark from '../assets/icons/close-icon-dark.svg'

import IconButton from '@material-ui/core/IconButton'

export const NotificationComponent = ({ isNew, onActionClick, text, actionLabel, onClose }) => {
  return (
    <Box
      border="1px solid #023459"
      width="100%"
      display="flex"
      flexDirection="column"
      minHeight="125px"
      bgcolor="white"
      boxSizing="border-box"
    >
      {isNew && (
        <Box display="flex">
          <Box width="0" height="0" borderTop="23px solid #023459" borderRight="23px solid transparent" />

          <IconButton onClick={onClose} style={{ marginLeft: 'auto', padding: '8px', paddingBottom: '0' }}>
            <img src={CloseIconDark} />
          </IconButton>
        </Box>
      )}

      <Box display="flex" flexDirection="column" p={1.5} pt={isNew ? 0 : 2} height="100%">
        {text}
        {onActionClick && (
          <Button
            color="primary"
            variant="contained"
            fullWidth={false}
            style={{ marginLeft: 'auto', marginTop: 'auto' }}
            onClick={onActionClick}
          >
            {actionLabel}
          </Button>
        )}
      </Box>
    </Box>
  )
}

const TransferApproval = ({ notification }) => {
  const history = useHistory()
  const [order, setOrder] = useState()
  const { markNotificationAsRead } = useNotificationsContext()
  const { orders } = useProductsContext()

  useEffect(() => {
    if (orders && orders.length && !order) {
      const order = orders
        .map((order) => ({ ...order, data: jsonParser(order.data) }))
        .find(
          (order) => order.data.orderInfo && order.data.orderInfo.transferRequestId === notification.meta.transferId,
        )
      setOrder(order)
    }
  }, [orders])

  const hasApproveButton = !order || order.status === 'pendingApproval' || order.status === 'pending'
  const isNew = !notification.actions
  const onNotificationClick = () => {
    if (isNew) {
      markNotificationAsRead(notification)
    }
    if (hasApproveButton) {
      history.push(`/approveTransfer/${notification.meta.transferId}`)
      return
    }
    const { id } = order
    history.push(`/home/history/${id}`)
  }
  const onClose = () => {
    markNotificationAsRead(notification)
  }
  return (
    <NotificationComponent
      isNew={isNew}
      text={
        <>
          <Typography variant="body1" color="primary">
            <b>{notification.meta.recipientName}</b> has claimed your transfer of the ownership certificate of{' '}
            <b>{notification.meta.assetName}</b>
          </Typography>
        </>
      }
      onClose={onClose}
      onActionClick={onNotificationClick}
      actionLabel={hasApproveButton ? 'Approve transfer' : 'View'}
    />
  )
}

export const Notifications = () => {
  const { newNotifications, pubnubNotificationHistory, setIsNotificationHistoryOpen, isNotificationHistoryOpen } =
    useNotificationsContext()
  const { isDesktop } = useVaultContext()
  const [showElements, setShowElements] = useState(4)
  const lastNotification =
    newNotifications && newNotifications.length ? newNotifications[newNotifications.length - 1] : null
  const location = useLocation()

  useEffect(() => {
    onClose()
  }, [location.pathname])
  const onClose = () => {
    setIsNotificationHistoryOpen(false)
    setShowElements(4)
  }
  if (lastNotification && !isNotificationHistoryOpen) {
    return (
      <Box
        maxWidth="428px"
        width="100%"
        display="flex"
        flexDirection="column"
        zIndex="1000"
        position="fixed"
        top={!isDesktop ? '70px' : '105px'}
        right="0"
        boxSizing="border-box"
        pl={isDesktop ? '16px' : '16px'}
        pr={isDesktop ? '16px' : '16px'}
      >
        <TransferApproval notification={lastNotification} />
      </Box>
    )
  }
  return (
    isNotificationHistoryOpen && (
      <Box
        maxWidth="428px"
        width="100%"
        display="flex"
        flexDirection="column"
        zIndex="1000"
        position="fixed"
        top={!isDesktop ? '67px' : '102px'}
        pt={2}
        right="0"
        boxSizing="border-box"
        pl={isDesktop ? '16px' : '16px'}
        pr={isDesktop ? '16px' : '16px'}
        bgcolor="#F1F0EA"
        bottom="70px"
        style={{ overflowY: 'auto' }}
      >
        <Box pl={3} pt={1.5} pb={1.5} pr={2} bgcolor="#023459" display="flex" mb={1.5} boxSizing="border-box">
          <Typography style={{ fontSize: '18px', color: 'white' }}>Notifications</Typography>
          <IconButton onClick={onClose} style={{ marginLeft: 'auto', padding: 0 }}>
            <img src={CloseIcon} />
          </IconButton>
        </Box>
        {[...pubnubNotificationHistory].reverse().map((notification, index) => {
          if (showElements < index + 1) {
            return null
          }
          return (
            <Box display="flex" flexDirection="column" key={JSON.stringify(notification)}>
              <TransferApproval notification={notification} />
              <Box mt={1.5}></Box>
            </Box>
          )
        })}
        {showElements < pubnubNotificationHistory.length && (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography color="primary" style={{ cursor: 'pointer' }} onClick={() => setShowElements(showElements + 4)}>
              Show more
            </Typography>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowElements(showElements + 4)}
            >
              <path
                d="M10.5 15.75L21 26.25L31.5 15.75"
                stroke="#023459"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Box>
        )}
      </Box>
    )
  )
}
