/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useEffect, useState } from 'react'
import { useVaultContext } from './useVault'
import { lohkoBackendApi } from '../utils/constants'
import { useZippieIdContext } from '../hooks/useZippieId'

import axios from 'axios'

export const useWithdraw = () => {
  const { environment, lohkoEnv } = useVaultContext()
  const { accessToken } = useZippieIdContext()

  const updateRequirements = async (details, quoteId, type) => {
    const lohkoUrl = lohkoBackendApi[lohkoEnv]

    const response = await axios.post(`${lohkoUrl}/lohko/transferwise_post_recipient_requirements `, {
      quoteId,
      type,
      details,
    })
    return response.data
  }
  const setRecipient = async (currency, type, details) => {
    const lohkoUrl = lohkoBackendApi[lohkoEnv]

    const response = await axios.post(
      `${lohkoUrl}/lohko/transferwise_set_recipient`,
      {
        currency,
        type,
        details,
      },
      { headers: { 'x-access-token': accessToken } },
    )
    return response
  }

  const getRequirements = async (quoteId) => {
    const lohkoUrl = lohkoBackendApi[lohkoEnv]

    const response = await axios.post(`${lohkoUrl}/lohko/transferwise_recipient_requirements`, {
      quoteId,
    })
    return response.data
  }
  const getQuoteId = async (sourceCurrency, targetCurrency, sourceAmount) => {
    const lohkoUrl = lohkoBackendApi[lohkoEnv]

    const response = await axios.post(`${lohkoUrl}/lohko/transferwise_get_quote`, {
      sourceCurrency,
      targetCurrency,
      sourceAmount,
    })
    return response.data
  }

  const checkForRecipient = async () => {
    const lohkoUrl = lohkoBackendApi[lohkoEnv]

    const response = await axios.post(
      `${lohkoUrl}/lohko/transferwise_set_recipient`,
      {
        ask: true,
      },
      { headers: { 'x-access-token': accessToken } },
    )
    return response.data
  }
  return {
    updateRequirements,
    setRecipient,
    getRequirements,
    checkForRecipient,
    getQuoteId,
  }
}

const [WithdrawProvider, useWithdrawContext] = constate(useWithdraw)

export { WithdrawProvider, useWithdrawContext }
