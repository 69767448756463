import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useZippieIdContext } from '../hooks/useZippieId'
import { useLocation } from 'react-router-dom'

// eslint-disable no-undef
import Box from '@material-ui/core/Box'

const ZEN_DESK_KEY = ''

export const ZenDeskSdk = () => {
  const location = useLocation()
  const [position, setPosition] = useState('bottom')
  const { userInformation } = useZippieIdContext()
  const openWidget = () => {
    // eslint-disable-next-line no-undef
    zE('webWidget', 'show')
    // eslint-disable-next-line no-undef
    zE('webWidget', 'open')
    // eslint-disable-next-line no-undef
    zE('webWidget:on', 'close', function () {
      // eslint-disable-next-line no-undef
      zE('webWidget', 'hide')
    })
  }
  useEffect(() => {
    if (Object.keys(userInformation).length) {
      // eslint-disable-next-line no-undef
      zE('webWidget', 'identify', { name: userInformation.fullname, email: userInformation.email })
      // eslint-disable-next-line no-undef
      zE('webWidget', 'setLocale', 'en')
    }
  }, [userInformation])

  useEffect(() => {
    const isUp = location.pathname.includes('redeem-asset')

    setPosition(isUp ? 'top' : 'bottom')
  }, [location.pathname])
  const isDisplayed = !location.pathname.includes('terms-of-service')
  return isDisplayed ? (
    <Box position="fixed" bottom={position === 'top' ? '' : '80px'} right="30px" top={position === 'top' ? '20px' : ''}>
      <Button
        fullWidth={false}
        variant="contained"
        color="primary"
        onClick={openWidget}
        startIcon={
          <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 1.77273C19.1927 1.77273 24.2273 5.59394 24.2273 10.2897C24.2273 14.9855 19.1927 18.8067 13 18.8067C12.7006 18.8067 11.6212 18.7436 11.1564 18.7121L10.6758 18.6806L10.2424 18.9012C9.54121 19.2479 7.87879 20.083 6.25576 20.8945C6.46061 20.343 6.67333 19.7679 6.89394 19.2242L7.45333 17.8139L6.14545 17.0418C3.36424 15.403 1.76485 12.9448 1.76485 10.3055C1.77273 5.59394 6.80727 1.77273 13 1.77273ZM13 0C5.82242 0 0 4.60909 0 10.2897C0 13.6776 2.06424 16.6794 5.24727 18.5545C4.16 21.3121 3.15152 24.4242 3.15152 24.4242C3.15152 24.4242 9.4703 21.257 11.0303 20.4848C11.4164 20.5085 12.6061 20.5873 13 20.5873C20.1776 20.5873 26 15.9782 26 10.2976C26 4.60909 20.1776 0 13 0Z"
              fill="white"
            />
          </svg>
        }
        style={{
          maxWidth: '130px',
        }}
      >
        Get help{' '}
      </Button>
    </Box>
  ) : null
}
