/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useEffect, useState } from 'react'
import { useVaultContext } from './useVault'

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState(true)

  const onStart = async () => {}

  useEffect(() => {
    onStart()
  }, [])

  return {
    setIsLoading,
    isLoading,
  }
}

const [LoadingProvider, useLoadingContext] = constate(useLoading)

export { LoadingProvider, useLoadingContext }
