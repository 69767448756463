/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useState, useEffect, useRef } from 'react'
import { pubnubPublishKey, pubnubSubscribeKey } from '../utils/pubnub'
import PubNub from 'pubnub'
import { useUserSettingsContext } from './useUserSettings'

export const useNotifications = () => {
  const [notifications, setNotifications] = useState({})
  const [pubnubClient, setPubnubClient] = useState()
  const [pubnubNotificationHistory, setPubnubNotificationHistory] = useState([])
  const [newNotifications, setNewNotifications] = useState([])
  const [isNotificationHistoryOpen, setIsNotificationHistoryOpen] = useState(false)

  const newNotificationsRef = useRef(newNotifications)
  const setNewNotificationRef = (data) => {
    newNotificationsRef.current = data
    setNewNotifications(data)
  }
  const pubnubHistoryRef = useRef(pubnubNotificationHistory)
  const setPubNubHistoryRef = (data) => {
    pubnubHistoryRef.current = data
    setPubnubNotificationHistory(data)
  }

  const { pubkey } = useUserSettingsContext()

  const publishUserMessage = async () => {
    const userChannel =
      'ch-1-04151e25d065e252088e420a101548221daf36938e28c0dec2445dc19acb9f45ab8e025da597f7a9a23d3ff01aa6ebf3aae6d77df39d5cb60c2d831874264f8e61'
    const meta = {
      assetName: 'PAMP Gold Bar - 1 g',
      recipientName: 'casd asdas',
      senderName: 'cosmin ionascu',
      transferId: '0xd2d9bb62bbf76c18212121eca9a94b4e0c0c808c37371c8e129c60e43b968698',
      type: 'TransferApproval',
    }
    const message = {
      title: 'Pending Lohko Wallet Transfer Approval',
      description:
        'Recipient name has claimed the digital ownership certificate you sent, do you want to accept this transfer?',
    }
    const result = await pubnubClient.publish({
      channel: userChannel,
      message: message,
      meta: meta,
    })
  }
  const onMessage = (messageEvent) => {
    const newNotifications = [...newNotificationsRef.current, { ...messageEvent, meta: messageEvent.userMetadata }]
    setNewNotificationRef(newNotifications)
    const newHistory = [...pubnubHistoryRef.current, { ...messageEvent, meta: messageEvent.userMetadata }]

    setPubNubHistoryRef(newHistory)
  }
  const initNotificationClient = async () => {
    const userChannel = `ch-1-${pubkey}`
    const pubnubLohkoClient = new PubNub({
      publishKey: pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey,
      uuid: pubkey,
    })

    pubnubLohkoClient.addListener({
      status: function (statusEvent) {
        if (statusEvent.category === 'PNConnectedCategory') {
          console.error('PubNub Connected')
        }
      },
      message: function (messageEvent) {
        onMessage(messageEvent)
        console.error('new message event', messageEvent)
      },
    })

    pubnubLohkoClient.subscribe({
      channels: [userChannel],
    })
    setPubnubClient(pubnubLohkoClient)
  }

  const getMessageHistory = async (pubnubLohkoClient) => {
    const userChannel = `ch-1-${pubkey}`

    const history = await pubnubLohkoClient.fetchMessages({
      channels: [userChannel],
      count: 25,
      includeMessageActions: true,
      includeMeta: true,
      includeMessageType: true,
      end: '16221393403090000', // notifications start from 27 may
    })
    const channelHistory = history.channels[userChannel]
    const newNotifications = channelHistory ? channelHistory.filter((notification) => !notification.actions) : []
    setPubNubHistoryRef(channelHistory || [])
    setNewNotificationRef(newNotifications)
  }

  const markNotificationAsRead = async (notification) => {
    const updated = newNotifications.filter((n) => JSON.stringify(n) === JSON.stringify(notification))
    setNewNotificationRef(updated)
    await addMessageAction(notification, { type: 'reaction', value: 'seen' })
  }

  const addMessageAction = async (message, action) => {
    const result = await pubnubClient.addMessageAction({
      channel: message.channel,
      messageTimetoken: message.timetoken,
      action: action,
    })
    getMessageHistory(pubnubClient)
  }
  const enqueueNotification = (details) => {
    const id = details.id

    const newNotifications = { [id]: details }
    setNotifications(newNotifications)
  }

  const updateNotification = (details) => {
    const id = details.id

    if (notifications[id]) {
      const newNotification = { ...notifications[id], ...details }
      const newNotifications = { ...notifications, [id]: newNotification }
      setNotifications(newNotifications)
    }
  }
  const closeNotification = (id) => {
    if (notifications[id]) {
      const newNotifications = { ...notifications }
      delete newNotifications[id]
      setNotifications(newNotifications)
    }
  }

  useEffect(() => {
    if (pubkey) {
      initNotificationClient()
    }
  }, [pubkey])
  useEffect(() => {
    if (pubnubClient) {
      getMessageHistory(pubnubClient)
    }
  }, [pubnubClient])
  return {
    enqueueNotification,
    closeNotification,
    updateNotification,
    notifications,
    setNotifications,
    newNotifications,
    pubnubNotificationHistory,
    markNotificationAsRead,
    isNotificationHistoryOpen,
    setIsNotificationHistoryOpen,
    publishUserMessage,
  }
}

const [NotificationsProvider, useNotificationsContext] = constate(useNotifications)

export { NotificationsProvider, useNotificationsContext }
