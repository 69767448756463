/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useEffect, useState } from 'react'
import { useVaultContext } from './useVault'
import { useZippieIdContext } from './useZippieId'
import axios from 'axios'
import { sha256 } from '../utils/sha256'
import { lohkoBackendApi } from '../utils/constants'
import { ZIPPIE_ID_APP } from '../utils/constants'
import { useWalletContext } from './useWallet'
import { useHistory } from 'react-router-dom'
import { useUserSettingsContext } from '../hooks/useUserSettings'
import { jsonParser } from '../utils/jsonParser'

export const useDashboard = () => {
  const { vault, lohkoEnv, environment, vaultIsReady, closeKlaatuPopUp } = useVaultContext()
  const { isLoggedIn, accessToken, setAccessToken, signUpWithZippie, userInformation } = useZippieIdContext()
  const {
    setIsAdmin,
    setHas2FAActive,
    setIsUserBlacklisted,
    setUserSettings,
    addUserSettings,
    setPubKey,
    setHasValidKyc,
    setUserId,
  } = useUserSettingsContext()
  const { syncAccounts } = useWalletContext()

  const [isSignInProgress, setIsSignInProgress] = useState(false)

  //keep it here for now
  const [blogPosts, setBlogPosts] = useState([])

  const [passwordHash, setPasswordHash] = useState(false)

  const history = useHistory()
  const getSecp256k1 = async () => {
    const zippieIdServiceSecp256k1 = await vault.ipc.createClient(ZIPPIE_ID_APP[environment], 'zippie/id/secp256k1')
    let privateKey = localStorage.getItem('privateKey')
    let sender = localStorage.getItem('sender')
    if (!sender) {
      const keyS = 'm/0'
      sender = await zippieIdServiceSecp256k1.keyInfo(keyS)
      localStorage.setItem('sender', JSON.stringify(sender))
    } else {
      sender = jsonParser(sender)
    }
    if (!privateKey) {
      privateKey = await zippieIdServiceSecp256k1.extractKey('m/0')
      localStorage.setItem('privateKey', JSON.stringify(privateKey))
    } else {
      privateKey = jsonParser(privateKey)
    }
    return {
      secp256k1: zippieIdServiceSecp256k1,
      privateKey,
      sender,
    }
  }

  const createSignature = async (privateKey, secp256k1, type) => {
    const hashInstruction = await sha256(type)

    const sig = await secp256k1.signWith(privateKey.privkey, hashInstruction)
    const signature = { rs: sig.signature, recovery: sig.recovery }
    return signature
  }

  const startRecovery = async () => {
    const passwordHash = localStorage.getItem('passwordHash')
    const { privateKey, sender, secp256k1 } = await getSecp256k1()
    const signature = await createSignature(privateKey, secp256k1, 'recovery')

    const response = await axios.post(`${lohkoBackendApi[lohkoEnv]}/auth/start_recovery`, {
      pubkey: sender.pubkey,
      signature: signature,
      password: passwordHash,
    })
    const flowName = response.data.flowName
    const accessToken = response.data.accessToken && response.data.accessToken.token

    return {
      flowName,
      accessToken,
      userRecovered: response.data.userRecovered,
    }
  }
  const startKYC = async (sumsubKycValid, externalActionId) => {
    const api = sumsubKycValid ? 'start_liveness_check' : 'start_kyc'
    const response = await axios.post(
      `${lohkoBackendApi[lohkoEnv]}/auth/${api}`,
      { externalActionId },
      { headers: { 'x-access-token': accessToken } },
    )
    const flowName = response.data.flowName
    const accToken = response.data.accessToken.token
    return {
      flowName,
      accessToken: accToken,
    }
  }

  const getUserSumSubEvents = async () => {
    const response = await axios.post(
      `${lohkoBackendApi[lohkoEnv]}/auth/user_sumsub_events`,
      {},
      { headers: { 'x-access-token': accessToken } },
    )
    return response.data
  }

  const signin = async (uData) => {
    setIsSignInProgress(true)

    let userData
    let passwordHash
    try {
      userData = uData ? uData : await signUpWithZippie('signin')
      const savedAccounts = localStorage.getItem('accounts') ? jsonParser(localStorage.getItem('accounts')) : null

      const { privateKey, sender, secp256k1 } = await getSecp256k1()
      const accounts = savedAccounts ? savedAccounts : (await syncAccounts()).accounts
      const signature = await createSignature(privateKey, secp256k1, 'signin')
      passwordHash = userData ? userData.passwordHash : localStorage.getItem('passwordHash')
      let path = 'signin_with_password'

      if (!passwordHash) {
        path = 'signin'
      }
      const { data } = await axios.post(`${lohkoBackendApi[lohkoEnv]}/auth/${path}`, {
        pubkey: sender.pubkey,
        signature: signature,
        accountErc20: accounts[0].accountAddress,
        accountErc721: accounts[1].accountAddress,
        password: passwordHash,
      })
      const { accessToken, roles, kycValid, blacklisted, userSettings, id } = data
      //signup
      let uSettings = userSettings
      if (uData) {
        uSettings = {
          ...userSettings,
          ...{
            termsOfService: {
              version: 'v1',
              isAccepted: true,
              timestamp: Date.now(),
            },
          },
        }
        await axios.post(
          `${lohkoBackendApi[lohkoEnv]}/users/update_user_settings`,
          {
            settings: uSettings,
          },
          { headers: { 'x-access-token': accessToken } },
        )
      }
      if (uSettings) {
        addUserSettings(uSettings)
      }
      setIsUserBlacklisted(blacklisted)
      const isAdmin = roles.some((role) => role === 'ROLE_ADMIN') || environment === 'development'
      setIsAdmin(isAdmin)
      setHasValidKyc(kycValid)
      setPubKey(sender.pubkey)
      setAccessToken(accessToken)
      setUserId(id)
      setIsSignInProgress(false)

      closeKlaatuPopUp()
    } catch (error) {
      console.error('error', error)
      const response = error.response
      if (response) {
        console.error('error', error.response)
        if (response.data && response.data.message === 'Failed: user not found') {
          signup(userData)
        }
        if (response.data && response.data.message === 'Failed: user blacklisted') {
          setIsUserBlacklisted(true)
        }
        if (response.data && response.data.message === 'Failed: Invalid Password') {
          setPasswordHash(passwordHash)
          history.push('/recovery')
        }
      }
      closeKlaatuPopUp()
      setIsSignInProgress(false)

      throw error
    }
  }

  const signup = async (uData) => {
    setIsSignInProgress(true)
    let userData
    let passwordHash

    if (accessToken) {
      return
    }
    try {
      userData = uData ? uData : await signUpWithZippie('signUp')
      passwordHash = userData && userData.passwordHash ? userData.passwordHash : localStorage.getItem('passwordHash')
      const userInfo = userData ? userData.userInformation : userInformation
      const { privateKey, sender, secp256k1 } = await getSecp256k1()
      const signature = await createSignature(privateKey, secp256k1, 'signup')
      const hashInstruction = await sha256('signup')

      await axios.post(`${lohkoBackendApi[lohkoEnv]}/auth/signup_with_password`, {
        pubkey: sender.pubkey,
        name: userInfo.fullname,
        email: userInfo.email,
        signature: signature,
        password: passwordHash,
        scope: hashInstruction,
      })
      signin(userData)
    } catch (error) {
      console.error(error)
      setIsSignInProgress(false)
    }
  }

  const enroll2fa = async (authenticator) => {
    if (accessToken) {
      try {
        console.log('*** Got Access Token')
        const response = await axios.post(
          `${lohkoBackendApi[lohkoEnv]}/auth/enroll_2fa`,
          { authenticator },
          { headers: { 'x-access-token': accessToken } },
        )
        console.log(response.data)
        return response.data
      } catch (err) {
        console.error('*** Error ', err.message)
      }
    }
  }

  const verify2faEnrollment = async (code) => {
    if (accessToken) {
      try {
        const response = await axios.post(
          `${lohkoBackendApi[lohkoEnv]}/auth/verify_2fa_enrollment`,
          { code },
          { headers: { 'x-access-token': accessToken } },
        )
        // response.data is {isValid: true/false}
        return response.data
      } catch (err) {}
    }
  }

  const verify2faCode = async (code) => {
    if (accessToken) {
      try {
        const response = await axios.post(
          `${lohkoBackendApi[lohkoEnv]}/auth/verify_2fa_code`,
          { code },
          { headers: { 'x-access-token': accessToken } },
        )
        // response.data is {isValid: true/false}
        return response.data.isValid
      } catch (err) {
        throw err
      }
    }
  }
  const isUser2FAEnrolled = async (token) => {
    let accToken = token || accessToken
    if (accToken) {
      try {
        const { data } = await axios.post(
          `${lohkoBackendApi[lohkoEnv]}/auth/is_user_2fa_enrolled`,
          {},
          {
            headers: { 'x-access-token': accessToken },
          },
        )
        setHas2FAActive(data.enrolled !== 0 && data.enrolled !== null)
        return data.enrolled !== 0 && data.enrolled !== null
      } catch (err) {}
    }
  }

  const disable2FA = async (code2fa) => {
    if (accessToken) {
      try {
        const { data } = await axios.post(
          `${lohkoBackendApi[lohkoEnv]}/auth/disable_2fa`,
          {
            code2fa,
          },
          {
            headers: { 'x-access-token': accessToken },
          },
        )
        setHas2FAActive(data.enrolled !== 0)
        return data.enrolled !== 0
      } catch (err) {}
    }
  }
  const fetchBlogPosts = async () => {
    const { data } = await axios.get(`${lohkoBackendApi[lohkoEnv]}/lohko/get-blog-posts`)

    setBlogPosts(data)
  }
  useEffect(() => {
    if (vaultIsReady) {
      fetchBlogPosts()
    }
  }, [vaultIsReady])

  useEffect(() => {
    if (accessToken) {
      isUser2FAEnrolled()
    }
  }, [accessToken])

  useEffect(() => {
    if (isLoggedIn && vaultIsReady) {
      signin()
    }
  }, [isLoggedIn, vaultIsReady])

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        if (error.response && error.response.data && error.response.data.message === 'Failed: user blacklisted') {
          setIsUserBlacklisted(true)
        }
        return Promise.reject(error)
      },
    )
  }, [])
  return {
    signup,
    signin,
    enroll2fa,
    verify2faEnrollment,
    verify2faCode,
    isUser2FAEnrolled,
    disable2FA,
    startRecovery,
    startKYC,
    getUserSumSubEvents,
    isSignInProgress,
    blogPosts,
  }
}

const [DashboardProvider, useDashboardContext] = constate(useDashboard)

export { DashboardProvider, useDashboardContext }
