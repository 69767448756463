/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useVaultContext } from './useVault'
import { lohkoBackendApi } from '../utils/constants'
import { useZippieIdContext } from '../hooks/useZippieId'
import { useNotificationsContext } from '../hooks/useNotifications'
import { jsonParser } from '../utils/jsonParser'

export const checkOrder = async (payload, env) => {
  const lohkoUrl = lohkoBackendApi[env]
  const response = await axios.post(`${lohkoUrl}/lohko/get_order_status`, payload)
  return response.data
}
export const useProducts = () => {
  const [productsStock, setProductsStock] = useState({})
  const [products, setProducts] = useState([])
  const [isProductsReady, setIsProductsReady] = useState(false)
  const [orders, setOrders] = useState([])

  const { setNotifications } = useNotificationsContext()
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { vaultIsReady, environment, lohkoEnv } = useVaultContext()
  const { accessToken } = useZippieIdContext()
  const getPrice = async (name) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/get_product_price`
    const response = await axios.post(url, {
      name,
    })

    return response.data
  }

  const getProductsPrice = async (name) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/get_products_price`
    const response = await axios.get(url)
    return response.data
  }
  const getTransferProductInfo = async (id) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/get_transfer_request`
    const response = await axios.post(url, {
      id,
    })
    return response.data
  }
  const redeemTransfer = async (id, code, accessToken) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/redeem_transfer_request`
    const response = await axios.post(
      url,
      {
        id,
        code,
      },
      { headers: { 'x-access-token': accessToken } },
    )
    return response.data
  }
  const verifyTransferToken = async (id, code) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/verify_transfer_request`
    const response = await axios.post(url, {
      id,
      code,
    })
    return response.data
  }
  const checkIfOrderIsDelivered = async (orderDetails) => {
    try {
      const payload = {
        orderId: orderDetails.orderId,
        keccakOrderId: orderDetails.keccakOrderId,
      }
      const response = await checkOrder(payload, lohkoEnv)
      if (response.status === 'created' || response.status === 'processing') {
        setTimeout(() => checkIfOrderIsDelivered(orderDetails), 3000)
        return
      }
      if (
        response.status === 'timeout' ||
        response.error ||
        response.status === 'failed to deliver' ||
        response.status === 'failure-to-deliver'
      ) {
        setNotifications((notifications) => {
          const transactionsDetails = {
            ...orderDetails,
            isCompleted: false,
            error: response.status || response.error,
          }
          const orderId = orderDetails.orderId

          if (notifications[orderId]) {
            const newNotification = { ...notifications[orderId], ...transactionsDetails }
            return { ...notifications, [orderId]: newNotification }
          }
          return notifications
        })
      }
      if (response.status === 'delivered') {
        setNotifications((notifications) => {
          const transactionsDetails = {
            isCompleted: true,
          }
          const orderId = orderDetails.orderId

          if (notifications[orderId]) {
            const newNotification = { ...notifications[orderId], ...transactionsDetails, title: 'Order completed' }
            return { ...notifications, [orderId]: newNotification }
          }
          return notifications
        })
      }
    } catch (e) {
      console.error(e)
      setTimeout(() => checkIfOrderIsDelivered(orderDetails), 3000)
    }
  }
  const getAllOrders = async (id) => {
    const lohkoUrl = lohkoBackendApi[lohkoEnv]

    const response = await axios.post(
      `${lohkoUrl}/lohko/get_user_transactions`,
      {},
      { headers: { 'x-access-token': accessToken } },
    )
    setOrders(response.data)
    return response.data
  }

  const getPendingOrders = async () => {
    const allOrders = await getAllOrders()
    const pendingOrders = allOrders
      .filter(
        (order) =>
          (order.status === 'pending' || order.status === 'submitted' || order.status === 'processing') &&
          order.type !== 'deposit',
      )
      .map((order) => {
        const { id, status, data } = order
        const { orderInfo } = jsonParser(data)
        const title = {
          transfer: `Transfer - The recipient hasn’t claimed yet.`,
          buy: `Purchase - Your bar is on its way to vault. This take about 48 hours. `,
          sell: 'Sell - Waiting funds to be deposited.',
        }
        const currency = orderInfo.currency || 'USD'
        const price = orderInfo.amount ? orderInfo.amount : orderInfo.prices ? orderInfo.prices[currency] : 0
        return {
          id,
          status,
          name: orderInfo.assetInfo && orderInfo.assetInfo.name,
          currency,
          price: price,
          quantity: '1',
          title: title[orderInfo.type],
          category: 'pending',
        }
      })
    return pendingOrders
  }

  const cancelPendingOrder = async (orderId) => {
    const lohkoUrl = lohkoBackendApi[lohkoEnv]

    const response = await axios.post(
      `${lohkoUrl}/lohko/cancel_transfer_request`,
      { orderId },
      { headers: { 'x-access-token': accessToken } },
    )
    getAllOrders()
    return response.data
  }

  const getProducts = async () => {
    try {
      const lohkoUrl = lohkoBackendApi[lohkoEnv]
      const arr = [await axios.get(`${lohkoUrl}/products/all`), await getProductsPrice()]
      const [response, productsPrice] = await Promise.all(arr)
      const publishedProducts = response.data

      const newProducts = publishedProducts.reduce((acc, item) => {
        const productName = item.name
        const isFractional = productName === 'Lohko Gram Gold' || productName === 'Lohko Gram Silver'

        const productInfo = item
        const hasStock = productsPrice[productName] && productsPrice[productName].providerStock > 0

        const prices = productsPrice[productName] ? productsPrice[productName].prices : {}
        const sellPrices = productsPrice[productName] ? productsPrice[productName].sellPrices : {}

        return {
          ...acc,
          [productName]: {
            ...productInfo,
            price: prices.USD,
            prices,
            sellPrices,
            hasStock,
            isFractional,
            providerStock: productsPrice[productName] ? productsPrice[productName].providerStock : 0,
          },
        }
      }, {})
      setProducts(newProducts)
      setIsProductsReady(true)
    } catch (e) {
      console.error(e)
    }
  }

  const hasProductStock = (productName) => {
    const hasLohkoStock = productsStock[productName] || []

    return (products[productName] && products[productName].hasStock) || hasLohkoStock.length > 0
  }
  const getStock = async (refresh) => {
    try {
      const url = `${lohkoBackendApi[lohkoEnv]}/lohko/get_stock`
      const stockResponse = await axios.post(url, { refresh: false })

      const finalProducts = Object.keys(stockResponse.data).reduce((acc, key) => {
        const product = stockResponse.data[key]
        const productName = product.info.name
        const finalProduct = {
          ...product,
        }

        if (acc[productName]) {
          return { ...acc, [productName]: [...acc[productName], finalProduct] }
        }
        return { ...acc, [productName]: [finalProduct] }
      }, {})

      setProductsStock(finalProducts)
      console.error(finalProducts)
      return finalProducts
    } catch (e) {
      console.error(e)
    }
  }

  const topUpLohkoAccount = async (amount, currency, recipientAddress, code2fa) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/create_order`
    const response = await axios.post(
      url,
      {
        topup: amount,
        currency: currency,
        recipientAddress,
        code2fa,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }

  const withdrawAccount = async (amount, sourceCurrency, code2fa, withdrawUSDC, recipientAddress) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/create_order`
    const response = await axios.post(
      url,
      {
        sourceCurrency,
        amount,
        withdraw: !withdrawUSDC,
        withdraw_usdc: withdrawUSDC,
        userId: accessToken,
        code2fa,
        recipientAddress,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }

  const createOrder = async (recipientAddress, product, quantity, type) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/create_order`
    const response = await axios.post(
      url,
      {
        recipientAddress,
        product,
        quantity,
        type,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }

  const commitOrder = async (keccakOrderId, code2fa) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/commit_order`
    const response = await axios.post(
      url,
      {
        keccakOrderId,
        code2fa,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }

  const transferAsset = async (recipientEmail, collectibleAddress, metadata, code2fa) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/create_order`
    const response = await axios.post(
      url,
      {
        metadata,
        recipientEmail,
        item: collectibleAddress,
        type: 'transfer',
        code2fa,
        senderApproval: true,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }

  const approveTransferRequest = async (id, code2fa) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/approve_transfer_request`
    const response = await axios.post(
      url,
      {
        id,
        code2fa,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }

  const depositCrypto = async (amount, recipientAddress, code2fa) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/create_order`
    const response = await axios.post(
      url,
      {
        topup: amount,
        recipientAddress,
        code2fa,
        topup_usdc: true,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }
  const sellAssetQuote = async (collectableAddress, currency, type) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/get_sell_item_quote`
    const response = await axios.post(
      url,
      {
        item: collectableAddress,
        currency,
        type,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }
  const sellFractionalAssetQuote = async (collectableAddress, currency, quantity) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/get_sell_fractional_asset_quote`
    const response = await axios.post(
      url,
      {
        product: collectableAddress,
        currency,
        quantity,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }
  const sellAsset = async (collectableAddress, currency, metadata, code2fa, isFractional, quantity) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/create_order`
    const response = await axios.post(
      url,
      {
        metadata,
        currency,
        item: isFractional ? '' : collectableAddress,
        product: isFractional ? collectableAddress : '',
        type: isFractional ? 'sell_fractional_asset' : 'sell',
        code2fa,
        quantity,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }
  const creatNFTApi = async (collectableAddress, currency, metadata, code2fa, years, recipientAddress) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/create_order`
    const response = await axios.post(
      url,
      {
        metadata,
        currency,
        item: collectableAddress,
        type: 'convert_to_nft',
        code2fa,
        years,
        recipientAddress,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }

  const getConvertNFTQuote = async (item, selectedYear) => {
    const url = `${lohkoBackendApi[lohkoEnv]}/lohko/get_convert_to_nft_quote`
    const response = await axios.post(
      url,
      {
        item: item,
        years: selectedYear,
      },
      { headers: { 'x-access-token': accessToken } },
    )

    return response.data
  }
  useEffect(() => {
    if (vaultIsReady) {
      getProducts()
      getStock()
    }
  }, [vaultIsReady])

  useEffect(() => {
    if (accessToken) {
      getAllOrders()
    }
  }, [accessToken])

  return {
    products,
    createOrder,
    isProductsReady,
    checkIfOrderIsDelivered,
    getStock,
    topUpLohkoAccount,
    productsStock,
    getPrice,
    getAllOrders,
    transferAsset,
    getTransferProductInfo,
    verifyTransferToken,
    redeemTransfer,
    orders,
    cancelPendingOrder,
    withdrawAccount,
    sellAsset,
    sellAssetQuote,
    creatNFTApi,
    getPendingOrders,
    depositCrypto,
    commitOrder,
    hasProductStock,
    approveTransferRequest,
    getConvertNFTQuote,
    getProducts,
    sellFractionalAssetQuote,
  }
}

const [ProductsProvider, useProductsContext] = constate(useProducts)

export { ProductsProvider, useProductsContext }
