import UnitedKingdom from '../assets/logos/united-kingdom 1.png'
import EuropeanUnion from '../assets/logos/european-union 1.png'
import UnitedStates from '../assets/logos/united-states 1.png'
import USDClogo from '../assets/logos/usd-coin-usdc-logo.svg'
import Singapore from '../assets/logos/singapore.png'

export const tokenCurrencies = {
  'LOHKO-USD': {
    currencySymbol: '$',
    logo: UnitedStates,
    currency: 'USD',
  },
  'LOHKO-EUR': {
    currencySymbol: '€',
    logo: EuropeanUnion,
    currency: 'EUR',
  },
  'LOHKO-SGD': {
    currencySymbol: 'S$',
    logo: Singapore,
    currency: 'SGD',
  },
  'LOHKO-GBP': {
    currencySymbol: '£',
    logo: UnitedKingdom,
    currency: 'GBP',
  },
  'LOHKO-USDC': {
    currencySymbol: '$',
    logo: USDClogo,
    currency: 'USD',
  },
}
