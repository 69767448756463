import React from 'react'

import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Route, Switch, Redirect } from 'react-router-dom'
import loadable from '@loadable/component'
import Box from '@material-ui/core/Box'
import { DashboardAppRoutes } from './dashboard/DashboardAppRoutes'
import { Notifications } from './components/NotificationComponent'

import { useVaultContext } from './hooks/useVault'

const PortfolioView = loadable(() =>
  import(/* webpackChunkName: "PortfolioView", webpackPrefetch: 1 */ './views/PortfolioView'),
)

const HistoryView = loadable(() =>
  import(/* webpackChunkName: "HistoryView", webpackPrefetch: 1 */ './views/HistoryView'),
)

const ProductsView = loadable(() =>
  import(/* webpackChunkName: "ProductsView", webpackPrefetch: 1 */ './views/ProductsView'),
)
const CategoryView = loadable(() =>
  import(/* webpackChunkName: "CategoryView", webpackPrefetch: 1 */ './views/CategoryView'),
)
const RedeemAssetsView = loadable(() =>
  import(/* webpackChunkName: "RedeemAssetsView", webpackPrefetch: 1 */ './views/RedeemAssetsView'),
)

const TransferApprovalView = loadable(() =>
  import(/* webpackChunkName: "TransferApprovalView", webpackPrefetch: 1 */ './views/TransferApprovalView'),
)

const SettingsView = loadable(() =>
  import(/* webpackChunkName: "SettingsView", webpackPrefetch: 1 */ './views/SettingsView'),
)
const TransferView = loadable(() =>
  import(/* webpackChunkName: "SettingsView", webpackPrefetch: 1 */ './views/TransferView'),
)
const DepositView = loadable(() =>
  import(/* webpackChunkName: "DepositView", webpackPrefetch: 1 */ './views/DepositView'),
)
const HistoryDetailsView = loadable(() =>
  import(/* webpackChunkName: "HistoryDetailsView", webpackPrefetch: 1 */ './views/HistoryDetailsView'),
)

const Enroll2FAView = loadable(() =>
  import(/* webpackChunkName: "Enroll2FAView", webpackPrefetch: 1 */ './views/Enroll2FAView'),
)
const BuyAssetsView = loadable(() =>
  import(/* webpackChunkName: "BuyAssetsView", webpackPrefetch: 1 */ './views/BuyAssetsView'),
)
const WithdrawView = loadable(() =>
  import(/* webpackChunkName: "WithdrawView", webpackPrefetch: 1 */ './views/WithdrawView'),
)

const SellView = loadable(() => import(/* webpackChunkName: "SellView", webpackPrefetch: 1 */ './views/SellView'))
const RecoveryView = loadable(() =>
  import(/* webpackChunkName: "RecoveryView", webpackPrefetch: 1 */ './views/RecoveryView'),
)
const KYCView = loadable(() => import(/* webpackChunkName: "KYCView", webpackPrefetch: 1 */ './views/KYCView'))
const AdminView = loadable(() => import(/* webpackChunkName: "AdminView", webpackPrefetch: 1 */ './views/AdminView'))

const BankDetailsView = loadable(() =>
  import(/* webpackChunkName: "BankDetailsView", webpackPrefetch: 1 */ './views/BankDetailsView'),
)

const CoverPageView = loadable(() =>
  import(/* webpackChunkName: "CoverPageView", webpackPrefetch: 1 */ './views/CoverPageView'),
)
const TermsOfServiceView = loadable(() =>
  import(/* webpackChunkName: "TermsOfServiceView", webpackPrefetch: 1 */ './views/TermsOfServiceView'),
)
const WelcomeScreenView = loadable(() =>
  import(/* webpackChunkName: "WelcomeScreenView", webpackPrefetch: 1 */ './views/WelcomeScreenView'),
)
const NFTView = loadable(() => import(/* webpackChunkName: "NFTView", webpackPrefetch: 1 */ './views/NFTView'))
const AssetView = loadable(() => import(/* webpackChunkName: "AssetView", webpackPrefetch: 1 */ './views/AssetView'))

export const MainAppRoutes = () => {
  const { isDesktop } = useVaultContext()

  return (
    <Switch>
      <Route path="/home">
        <Box display="flex" flexDirection="column" flexGrow="2">
          <Header />
          <Notifications />

          <Box
            display="flex"
            flexDirection="column"
            flexGrow="2"
            pl={isDesktop ? '8%' : 2}
            pr={isDesktop ? '8%' : 2}
            pt={3}
            overflowX="hidden"
          >
            <Route
              exact
              path="/home/cover"
              render={(props) => (
                <CoverPageView
                  {...props}
                  preload={[
                    PortfolioView,
                    HistoryView,
                    ProductsView,
                    SettingsView,
                    BuyAssetsView,
                    DepositView,
                    WithdrawView,
                    CategoryView,
                    BankDetailsView,
                  ]}
                />
              )}
            />
            <Route
              exact
              path="/home/portfolio"
              render={(props) => (
                <PortfolioView
                  {...props}
                  preload={[
                    HistoryView,
                    ProductsView,
                    SettingsView,
                    BuyAssetsView,
                    DepositView,
                    WithdrawView,
                    CategoryView,
                    BankDetailsView,
                  ]}
                />
              )}
            />
            <Route
              exact
              path="/home/history"
              render={(props) => (
                <HistoryView
                  {...props}
                  preload={[HistoryDetailsView, PortfolioView, SettingsView, BuyAssetsView, DepositView]}
                />
              )}
            />
            <Route
              exact
              path="/home/history/:orderId"
              render={(props) => <HistoryDetailsView {...props} preload={[HistoryView]} />}
            />
            <Route
              exact
              path="/home/products"
              render={(props) => <ProductsView {...props} preload={[CategoryView]} />}
            />
            <Route
              exact
              path="/home/settings"
              render={(props) => <SettingsView {...props} preload={[PortfolioView, BankDetailsView]} />}
            />
            <Route exact path="/home/admin" render={(props) => <AdminView {...props} preload={[]} />} />
            <Route
              exact
              path="/home/asset/:productId/:quantity"
              render={(props) => <AssetView {...props} preload={[CategoryView]} />}
            />
            <Route
              exact
              path="/home/products/:category"
              render={(props) => <CategoryView {...props} preload={[ProductsView, BuyAssetsView, TransferView]} />}
            />
            <Route exact path="/home/bank-details" render={(props) => <BankDetailsView {...props} preload={[]} />} />
          </Box>

          <Footer />
        </Box>
      </Route>
      <Route exact path="/welcome" render={(props) => <WelcomeScreenView {...props} preload={[PortfolioView]} />} />
      <Route exact path="/terms-of-service" render={(props) => <TermsOfServiceView {...props} preload={[]} />} />

      <Route
        exact
        path="/approveTransfer/:hash"
        render={(props) => <TransferApprovalView {...props} preload={[PortfolioView]} />}
      />

      <Route exact path="/redeem-asset" render={(props) => <RedeemAssetsView {...props} preload={[PortfolioView]} />} />
      <Route exact path="/withdraw" render={(props) => <WithdrawView {...props} preload={[PortfolioView]} />} />

      <Route
        exact
        path="/transfer/:assetId"
        render={(props) => <TransferView {...props} preload={[PortfolioView]} />}
      />
      <Route
        exact
        path="/buy/:productId/:quantity"
        render={(props) => <BuyAssetsView {...props} preload={[PortfolioView]} />}
      />

      <Route
        exact
        path="/sell/:productId/:quantity"
        render={(props) => <SellView {...props} preload={[PortfolioView]} />}
      />
      <Route exact path="/nft/:productId" render={(props) => <NFTView {...props} preload={[PortfolioView]} />} />
      <Route exact path="/deposit" render={(props) => <DepositView {...props} preload={[PortfolioView]} />} />
      <Route exact path="/enroll2fa" render={(props) => <Enroll2FAView {...props} preload={[PortfolioView]} />} />
      <Route exact path="/recovery" render={(props) => <RecoveryView {...props} preload={[]} />} />
      <Route exact path="/kyc" render={(props) => <KYCView {...props} preload={[]} />} />
      <DashboardAppRoutes />
    </Switch>
  )
}
