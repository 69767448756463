import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
const getHashValue = (key) => {
  var matches = window.location.hash.match(new RegExp(key + '=([^&]*)'))
  return matches ? matches[1] : null
}
const isSignUp = getHashValue('signup')
const isSignIn = getHashValue('signin')
const paymentHash = getHashValue('paymentHash')
const contentParams = {
  isSignUp: isSignUp ? true : false,
  isSignIn: isSignIn ? true : false,
  paymentHash,
}
ReactDOM.render(<App contentParams={contentParams} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
