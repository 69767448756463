import React, { useEffect, useState } from 'react'
import { I18nProvider } from '@lingui/react'
import { Route, HashRouter as Router, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'

import loadable from '@loadable/component'
import { VaultProvider, useVaultContext } from './hooks/useVault'
import { LanguageProvider, useLanguageContext } from './hooks/useLanguage'
import { ZippieIdProvider } from './hooks/useZippieId'
import { WalletProvider } from './hooks/useWallet'
import { ProductsProvider } from './hooks/useProducts'
import { WithdrawProvider } from './hooks/useWithdraw'
import { UserSettingsProvider, useUserSettingsContext } from './hooks/useUserSettings'

import { SnackbarProvider } from 'notistack'

import Box from '@material-ui/core/Box'
import { LoadingProvider } from './hooks/useLoading'
import { theme } from './Theme'

import { NotificationsProvider } from './hooks/useNotifications'
import { DashboardProvider, useDashboardContext } from './hooks/useDashboard'
import { ZenDeskSdk } from './components/ZenDeskSDK'
import Bowser from 'bowser'
import { MainAppRoutes } from './MainAppRoutes'
const BrowserNotSupportedView = loadable(() =>
  import(/* webpackChunkName: "BrowserNotSupportedView", webpackPrefetch: 1 */ './views/BrowserNotSupportedView'),
)
const BlackListedUserView = loadable(() =>
  import(/* webpackChunkName: "BlackListedUserView", webpackPrefetch: 1 */ './views/BlackListedUserView'),
)
const App = () => {
  const { language, languageStrings } = useLanguageContext()
  const { environment } = useVaultContext()
  const [isBrowserInvalid, setIsBrowserInvalid] = useState(false)

  const { isUserBlackListed } = useUserSettingsContext()

  // useEffect(() => {
  //   if (!vaultIsReady) {
  //     return
  //   }
  //   const timer = new IdleTimer({
  //     timeout: environment === 'dev' ? 3000 : 1200,
  //     onTimeout: () => {
  //       autoLogout()
  //     },
  //     onExpired: () => {
  //       autoLogout()
  //     },
  //   })

  //   return () => {
  //     timer.cleanUp()
  //   }
  // }, [vaultIsReady])
  const checkSupportedBrowser = () => {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const isValidBrowser = browser.satisfies({
      safari: '>=12.0',
      edge: '>=79',
      chrome: '>=78',
      firefox: '>=68',
      opera: '>=41',
    })
    if (!isValidBrowser) {
      setIsBrowserInvalid(true)
    }
    return isValidBrowser
  }
  useEffect(() => {
    checkSupportedBrowser()
    import('@sentry/browser').then((Sentry) => {
      Sentry.init({
        dsn: 'https://0359760eb7da4c66a7e31dc108d46442@sentry.zippie.org/29',
        release: environment,
      })
    })
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <I18nProvider language={language} catalogs={languageStrings}>
        <Box display="flex" flexDirection="column" overflowY="auto" minHeight="100vh" bgcolor={'white'}>
          {isBrowserInvalid ? (
            <>
              {' '}
              <Route exact path="/" render={(props) => <BrowserNotSupportedView {...props} preload={[]} />} />{' '}
              <Redirect to="/" />{' '}
            </>
          ) : (
            <>
              {isUserBlackListed ? (
                <Switch>
                  <Route exact path="/" render={(props) => <BlackListedUserView {...props} preload={[]} />} />{' '}
                  <Redirect to="/" />
                </Switch>
              ) : (
                <>
                  <MainAppRoutes />
                </>
              )}
              <ZenDeskSdk />
            </>
          )}
        </Box>
      </I18nProvider>
    </ThemeProvider>
  )
}

export default ({ contentParams }) => (
  <Router basename={'/'}>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      hideIconVariant={false}
    >
      <VaultProvider contentParams={contentParams}>
        <LoadingProvider>
          <LanguageProvider>
            <ZippieIdProvider>
              <UserSettingsProvider>
                <NotificationsProvider>
                  <ProductsProvider>
                    <WalletProvider>
                      <DashboardProvider>
                        <WithdrawProvider>
                          <App />
                        </WithdrawProvider>
                      </DashboardProvider>
                    </WalletProvider>{' '}
                  </ProductsProvider>
                </NotificationsProvider>
              </UserSettingsProvider>
            </ZippieIdProvider>
          </LanguageProvider>
        </LoadingProvider>
      </VaultProvider>
    </SnackbarProvider>
  </Router>
)
