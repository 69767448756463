export const jsonParser = (jsonData) => {
  let parsed = jsonData

  try {
    parsed = JSON.parse(jsonData)
  } catch (e) {
    console.error('data ', jsonData, ' is not a JSON')
  }

  return parsed
}
